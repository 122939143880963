

/* ALL STYLES */
.dashboard-layout section{
padding:0;
/* margin-bottom:0; */
}
.dashboard-layout h4{
/* border:1px solid red; */
text-transform:initial;
}
.dashboard-layout .copied{
color:var(--green-color);
}
.dashboard-layout .icon-container{
display: inline-block;
/* vertical-align:middle; */
}
.dashboard-layout .btn-container{
 text-align:initial;
}
.dashboard-layout .btn-container .cta-btn{
color:var(--white-color);
font-size:0.875rem;
text-transform:initial;
background:var(--white-color);
border: 1px solid #E7E7E7;
color:var(--black-color);
text-transform:capitalize; 
font-weight:500;
border-radius:100px;
min-width:80px;
}


.dashboard-layout .btn-container .cta-btn:not(:first-child){
margin-left:0.625rem;
}
.dashboard-layout .btn-container .cta-btn.active{
background:var(--black-color);
border:none;
color:var(--white-color);
}




/* DASHBOARD BOOK CARD */
.dashboard-layout .book-card{
/* border:2px solid blue; */
}
.dashboard-layout .book-card h5{
margin-bottom:0;
/* line-height:0; */
}
.dashboard-layout .book-card .card-footer{
padding:6px 13px 4px 13px;
}





/* DASHBOARD PROFILE */
.dashboard-layout .profile-card-container{
box-shadow: 0px 0px 7px 0px #6060603D;
background:var(--white-color);
}

.dashboard-layout .btn-container .cta-btn.view-profile-btn{
background:#f2f2f2;
padding:7.66px;
}






/* DASHBOARD HOME STYLES */
/* ***marked:: as to be changed later */
.webkit-scrollbar::-webkit-scrollbar,
.dashboard-layout .previous-webinar::-webkit-scrollbar{
height: 5px;
width: 6px;
}
.dashboard-layout .previous-webinar::-webkit-scrollbar-thumb{
 /* background:var(--grey-color); */
 border-radius: 2px;
}
.webkit-scrollbar::-webkit-scrollbar-thumb{
background:#f2f2f2;
}
.dashboard-layout .block-wrapper{
/* width:calc(100vw + 292px); */
width:calc(100vw + 624px);
display:flex;
flex-flow:row wrap;
align-items:center;
gap:40px;
}
.dashboard-layout .previous-webinar .block-item{
display:inline-block;
border-radius:12px;
flex-basis:292px;
}


/* 
***
webinar Count down 
***
 */
.dashboard-layout .webinar-countdown h5{
font-size:2.7rem;
font-weight:600;
margin-bottom:0 !important;
height:47px;
}
.dashboard-layout .webinar-countdown span{
font-size:0.69rem;
font-weight:600;
text-align:center;
display:block;
font-size:0.332rem;
}


/* here */
.webinar-countdown .countdown-timings div{
margin-right:14px;
flex-basis:42px;
}
.webinar-countdown .countdown-timings div + div{
position: relative;
}
.webinar-countdown .countdown-timings div + div::after{
position: absolute;
content: "";
top:50%;
left:-25%;
transform:translate(-50%,-50%);
display:inline-block;
height:7px;
width: 7px;
border-radius:50%;
background:#ffe24b;
}

.webinar-countdown .countdown-timings .seconds-container{
width:100%;
overflow:hidden;
line-height:47px;
height:47px;
}

.webinar-countdown .countdown-timings h5.seconds{
animation: slideUp 1s infinite;
position: relative;
}
@keyframes slideUp{
0%{
top:47px;
/* top:0; */
}
100%{
top:-47px;
/* top:-99.264px; */
}
}




/* DASHBOARD STUDY SHARE */
.dashboard-layout .add-material-container .studyshare-form::-webkit-scrollbar,
.studyshare-container .btn-container::-webkit-scrollbar{
height: 5px;
width: 6px;
}
.dashboard-layout .add-material-container .studyshare-form::-webkit-scrollbar,
.studyshare-container .btn-container::-webkit-scrollbar-thumb{
 /* background:var(--grey-color); */
 border-radius: 2px;
}

/* Add Material */
.dashboard-layout .add-material-card{
/* border:1px solid red; */
}
.dashboard-layout .add-material-container .studyshare-form{
height:calc(484px - 15vh);
}

/* ***Filter styles */
.filter-container .btn-container{
padding:4px 6px;
}
.filter-container .btn-container .cta-btn{
display:flex;
border:none;
background:transparent;
padding:0;
}
.filter-container .btn-container .cta-btn >*:first-child{
/* border:1px solid red; */
}







/* DASHBOARD -- SETTINGS */
.settings-container .flex{
/* border:1px solid red; */
}
/* .settings-container .flex>*{
border:1px solid green;
} */
.settings-container .flex>*:last-child{
padding:0.5rem;
}
.settings-container .flex a.active{
background: #F2F2F2;
border-radius:12px;
}
.settings-container .form-field button.cta-btn{
background:var(--black-color);
border-radius:25px;
}

.settings-container .form-field button.cta-btn:disabled{
background:#d9d9d9;
}
.settings-container .form-field input:focus{
border: 2px solid #AFAFAF;
}
.settings-container .notifications-container .enable-btn{
flex-basis:52px;
height:24px;
border-radius:25px;
padding:0rem;
text-align:right;
background:var(--blue-color);
}
.settings-container .notifications-container .enable-btn span{
height:20px;
width:20px;
background:var(--white-color);
border-radius:50%;
display:inline-block;
padding:9px;
text-align:initial;
margin:auto 0;
}
.settings-container .notifications-container .enable-btn.active{
}
.settings-container .personal-info-container input{
padding:0.5rem 0;
border:none;
transition:padding 0.5s ease;
}
.settings-container .personal-info-container input:focus{
 border:none;
}
.settings-container .personal-info-container input.active{
border: 2px solid #AFAFAF;
padding:0.5rem 1rem;
}




/* 
********************************
medium Devices
*******************************
*/
@media(min-width:768px){
.dashboard-layout .webinar-countdown h5{
font-size:5.66rem;
}
.dashboard-layout .block-wrapper{
width:calc(100vw + 348px);
}
.dashboard-layout .previous-webinar .block-item{
flex-basis:348px;
}
.webinar-countdown .countdown-timings div{
margin-right:28px;
flex-basis:88.941px;
}
.dashboard-layout .webinar-countdown h5{
height:99.26px;
}
.dashboard-layout .webinar-countdown span{
font-size:0.69rem;
}
.webinar-countdown .countdown-timings div + div::after{
height:14.635px;
width: 14.635px;
}
.webinar-countdown .countdown-timings div{
margin-right:28px;
flex-basis:88.941px;
}
.webinar-countdown .countdown-timings div + div{
position: relative;
}
.webinar-countdown .countdown-timings div + div::after{
position: absolute;
content: "";
top:50%;
left:-25%;
transform:translate(-50%,-50%);
display:inline-block;
height:14.635px;
width: 14.635px;
border-radius:50%;
background:#ffe24b;
}
.webinar-countdown .countdown-timings div{
margin-right:28px;
flex-basis:88.94px;
}
.webinar-countdown .countdown-timings .seconds-container{
line-height:99.26px;
height:99.26px;
}

@keyframes slideUp{
0%{
top:99.26px;
/* top:0; */
}
100%{
top:-99.26px;
/* top:-99.264px; */
}
}
}





/* 
********************************
Desktop Devices
*******************************
*/
@media(min-width:1024px){
.dashboard-layout .block-wrapper{
/* all the width values are correct */
/* width:calc(100vw - 292px); */
width:100%;
}

}