

/* Password Check Styles */
.password-check-list .indicator-checklist{
display:flex;
gap:2rem;
}
.password-check-list .indicator{
flex-basis:81.3px;
margin:auto;
background:#d9d9d9;
display:inline-block;
height:3px;
border-radius:8px;
transition:all 0.5s ease-out;
}