
.progress-bg{
width:100%;
height:0.25rem;
background:#fbfbfb;
border-radius:0 0 0.2rem 0.2rem;
position: relative;
}
.progress{
width:0;
height:0.5rem;
border-radius:8px;
transition:all 0.5s ease-out;
}
.flex-wrapper{
display:flex;
gap:20px;
align-items:center;
}
.flex-wrapper .progress{
/* border:1px solid red; */
}