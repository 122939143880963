
/* Auth Styles goes Here */
.auth-section{
padding:0;
}
.auth-section .form-container>*{
min-height:100vh;
}
.auth-section .form-container>*:first-child{
padding:2rem 7%;
}
.auth-section .form-container>*:last-child{
border-radius:45px 0px 0px 45px;
}
.auth-section .right-side{
background: #EAE7E2;
border-radius:inherit;
}
.auth-section .right-side .img-container{
height:100%;
}
.auth-section .right-side .img-container img{
height:inherit;
object-fit:cover;
/* object-position:center;  default postion style*/
}

.auth-section .logo-container{
 width:57px;
 height:57px;
 margin:1rem auto;
 padding:0;
}

.auth-section .form-field.terms {
text-align:center;
}
.auth-section .form-field.terms label{
display:inline-block;
text-transform:lowercase;
}
.auth-section .form-field.remember label{
display:inline-block;
}
.auth-section .form-field.terms label span{
text-decoration:underline;
color: #2C7E54;
}
.auth-section .form-field.remember input,
.auth-section .form-field.terms input{
width:initial;
}
.auth-section  .form-container .cta-btn{
width:100%;
border-radius:25px;
}
.auth-section  .form-container .cta-btn:disabled{
background:var(--disabled-color);
}


/*
============================
Password Reset Token Styles
============================
*/
.auth-section .otp-field {
  display: flex;
  gap: 10px;
  margin: 20px auto;
}
.auth-section .otp-field input {
  text-align: center;
  padding: 0.7rem;
  font-weight: 600;
  font-size: 1.2rem;
  /* width:56px;
  height:56px;
  margin:0 auto; */
  height:56px;
}
.auth-section .otp-field input:focus {
  border: 2px solid var(--primary-btn);
}
.auth-section .otp-field button.cta-btn:disabled{
background: var(--disabled-color);
color:var(--white-color);
}