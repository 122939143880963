

/* Dashboard Header Styles */
.dashboard-header{
/* border:1px solid red; */
}
.dashboard-header .header-section{
padding:0.5rem 0.75rem;
margin-bottom:0;
position:fixed;
width:100%;
background:var(--white-color);
z-index:1020;
top:0;
}
.dashboard-header .header-section .nav-wrapper{
/* border:1px solid green; */
}
.header-section .menu-bar{
display:inline-block;
padding:6px 8px;
}

/********
Subsidebar Menu
***************/
/*** 
SUBSIDE MENU  STYLES
***/
.dashboard-header .subside-menu-wrapper{
position: fixed;
top:0;
left:0;
cursor:pointer;
height:100%;
width:100%;
z-index:1030;
background: #00000040;
}
.dashboard-header .subside-menu-section{
position: absolute;
/* position: fixed; */
top:0;
left:0;
width:266px;
/* height:844px; */
height:100vh;
background:var(--white-color);
border-radius:0 8px 8px 0;
transform:translateX(-150%);
transition:0.3s ease-in;
z-index:1030;
padding-right:0.5rem;
overflow:auto;
}
.dashboard-header .subside-menu-section::-webkit-scrollbar {
  height: 5px;
  width: 6px;
}
.dashboard-header .subside-menu-section::-webkit-scrollbar-thumb {
 background:var(--grey-color);
 border-radius: 2px;
}

.dashboard-header .subside-menu-section.active{
transform:translateX(0);
transition:0.3s ease-out;
}
.dashboard-header .subside-menu-section a.active{
border-radius:0 25px 25px 0;
background:var(--green-color);
color:var(--white-color);
}


/* Drop Down List */
.dashboard-layout .dropdown-menu{
height:0px;
overflow:hidden;
transition:0.3s ease-out;
}
.dashboard-layout .dropdown-menu.active{
height:160px;
overflow:initial;
}
.dashboard-layout .dropdown .dropdown-list a{
background: #F2F2F280;
border-radius:12px 0 0 12px;
color:var(--black-color);
}
/* .dashboard-layout .dropdown .dropdown-list a.active{
 background:var(--green-color);
} */
.dashboard-layout .dropdown li{
margin-bottom:1rem;
}
.dashboard-layout .dropdown .nav-icon{
display:block;
height:40px;
width:40px;
border-radius:8px;
padding:8px;
background:var(--green-color);
}
/* .dashboard-layout .dropdown .dropdown-list a.active .nav-icon{
background:#F2F2F280;
} */





/* DESKTOP DEVICE */
@media(min-width:1280px){
.dashboard-header .header-section{
padding:0rem 0.75rem;
}
}