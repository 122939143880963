
/* Dashboard Side Menu */
.dashboard-layout .grid-container{
padding:0.875rem 1rem;
}
.dashboard-layout .aside{
background: #F2F2F2;
border-radius:0 8px 8px 0;
padding:0.35rem;
position: fixed;
height:100vh;
overflow:auto; 
/* top:calc(64px + 1.5rem); */
/* z-index:-1; Please do not use -negative  */
z-index:1010;
}
.dashboard-layout .side-menu{
min-height:120vh;
}
.dashboard-layout .aside::-webkit-scrollbar {
  height: 5px;
  width: 6px;
}
.dashboard-layout .aside::-webkit-scrollbar-thumb {
  /* background: var(--white-color); */
  border-radius: 2px;
}


.side-menu a{
font-weight:400;
padding:0.35rem;
text-align:center;
transition:0.5s ease;
width:68px;
height:65px;
font-size:0.625rem;
}
.side-menu a.active{
background: #2C7E54;
color:var(--white-color);
border-radius:8px;
/* line-height:65px; */
}

.side-menu li:not(:last-child){
margin-bottom:0.5rem;
}
.side-menu li:nth-child(4){
margin-bottom:6.375rem;
}


/* 
Services || Side menu 
 */
 .side-menu .services{
 z-index:1020;
 cursor: pointer;
 }
 .side-menu .services .dropdown{
 border:1px solid blue;
 background:var(--white-color);
 border:1px solid var(--green-color);
 /* border: 1px solid transparent;
 border-image-source: linear-gradient(122.95deg, #2C7E54 0%, rgba(44, 126, 84, 0.47) 100%);
 border-image-slice: 1; */
 border-radius:12px;
 position: fixed;
 top:172px;
 left:108px;
 z-index:1030;
 width:313px;
 box-shadow: 0px 0px 12px 0px #DADADA80;
 max-height:262px;
 padding:12px 16px 24px 6px;
 display:none;
 }
 .side-menu .services .dropdown.active{
display: block;
 }
.dashboard-layout .services .dropdown-list{
/* margin-bottom:12px;
border-radius: 12px;
background: rgba(245, 245, 245, 0.50); */
}
.dashboard-layout .services .dropdown-list a{
width:100%;
height:52px;
border-radius:12px;
}


/* .side-menu a.active svg path{
fill:var(--white-color);
} */






/* ===================================
 Desktop Device
======================================*/
@media(min-width:1024px){
.dashboard-layout .grid-container{
 padding:0.875rem 0rem;
}
.dashboard-layout .grid-container{
display:grid;
/* grid-template-columns:80px fit-content(1148px); */
/* grid-template-columns:80px minmax(1148 px, 1fr); */
grid-template-columns:80px 1fr;
gap:28px;
}
 
}