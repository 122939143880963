

  /** Activate Account Styles */
    .activate-account{
    display:flex;
    flex-flow:row wrap;
    justify-content:center;
    align-items:center;
    height:100vh;
    }
    
    .activate-account .modal-container{
     box-shadow:2px 2px 5px rgba(0,0,0,0.25);
     padding:1rem;
    text-align:center;
    }
    .activate-account .modal-container p{
    font-size:1rem;
    }
    .activate-account .modal-container h4{
    /* color:var(--green-color); */
    }
    .activate-account  .img-container{
     width:48px;
     display:inline-block;
     margin-bottom:1.2rem;
    }
    .activate-account .modal-container a{
    font-weight:600;
    text-transform:initial;
    }
    