
/* Grade Point Styles */
 .grade-point-container{
    max-width:280px;
    overflow:auto;
    }
.grade-point-container table{
    width:1480px;
    /* width:680px; */
    border-collapse: collapse;
    position:relative;
    }
    .grade-point-container::-webkit-scrollbar{
        width:5px;
        height:5px;
        }
        .grade-point-container::-webkit-scrollbar-thumb{
        border-radius:8px;
        background-color:#dddddd;
        }
   .grade-point-container button{
    border-radius:25px;
   }
   .grade-point-container tr button.cta-btn{
    padding:0.25rem 1rem;
    font-size:0.75rem;
    font-weight:500;
   }
  
  
   
    .grade-point-container table,
    .grade-point-container th,
    .grade-point-container td{
    border: 0.5px solid #7E7E7E;
    text-transform: capitalize;
    }
    .grade-point-container th,
    .grade-point-container td{
    padding:0.25rem;
    font-weight:500;
    text-align:initial;
    vertical-align:center;
    font-size:0.875rem;
    }
    /* .grade-point-container td{
    font-size:90%;
    }
    */
    /* table tr{
    background-color:#f8f8f8;
    } */
  
    .grade-point-container table thead tr:first-child{
    background-color:#f2f2f2;
    }
    .grade-point-container .flex-container{
    display:flex;
    gap:4%;
    align-items:center;
    margin:1rem auto;
    }
    .flex-container.bottom{
     border: 0.5px solid;
     border-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 51.42%, rgba(142, 142, 142, 0.6) 178.22%) 20;
     padding:0.5rem;
    }
    
  
    td.options{
    display:flex;
    flex-flow:row wrap;
    align-items:center;
    padding:0;
    justify-content:flex-end;
    border:1px solid teal;
    }
    .options > span{
    height:45px;
    }
    /* .save-btn,.cancel-btn{
    display:none;
    } */
    /* td{
    transition:padding 0.3s ease-out;
    }
    td.active{
    outline:2px solid var(--green-color);
    color:var(--green-color);
    transition:padding 0.3s ease-out;
    padding:0.5rem;
    border-radius:4px;
    } */
    
    .table-container button{
    /* width:100%; */
    padding:8px 16px;
    text-transform:initial;
    }
    .error{
    color: #f00;
    }
    .success{
    color:#228b22;
    }
    .warning{
    color:#daa520;
    }

    .grade-point-container button:disabled,
    .delete-row button:disabled{
    background:#a7a7a7;
    color:#000;
    transition:0.5s ease;
    }
    #download-btn{
    background:#fff;
    color:#000;
    border:1px solid gray;
    }
    .water-mark{
    position: absolute;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%) rotate(-45deg);
    z-index:1;
    font-size:1rem;
    text-transform:uppercase;
    opacity:0.5;
    font-weight:700;
    /* filter:blur(1px); */
    color:#000;
    }
    /* =========================================
     for Tablet device
    ============================================
     */
     @media(min-width:710px){
    .grade-point-container{
    max-width:710px;
    }
    .grade-point-container table{
    width:1400PX;
    }   
     }


      /* =========================================
     for Desktop device
    ============================================
     */
    @media(min-width:980px){
}

/* ==========================
 Large Desktop Screen
============================*/
@media(min-width:1200px){
.table-container{
 max-width:1200px;
}
 table{
width:100%;
}
}